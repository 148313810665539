<template>
  <div>
    <template v-if="user.is_online">
      <span class="text-green-600 text-sm font-normal flex items-center">
        <svg class="h-2 w-2 fill-green-600 mr-2" viewBox="0 0 6 6" aria-hidden="true">
          <circle cx="3" cy="3" r="3"></circle>
        </svg>
        Online
      </span>
    </template>
    <template v-else>
      <span class="text-slate-400 text-sm font-normal flex">
        {{ user.last_seen_format }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: [
      'user'
  ]
}
</script>