import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import UserPage from '../views/UserPage.vue'
import ProfilePage from '../views/ProfilePage.vue'
import SettingPage from '../views/SettingPage.vue'
import LikesPage from '../views/LikesPage.vue'
import WalletPage from '../views/WalletPage.vue'
import VipInfoPage from '../views/VipInfoPage.vue'
import MyMediaPage from '../views/MyMediaPage.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfilePage
    },
    {
        path: '/user/:id',
        name: 'user',
        component: UserPage
    },
    {
        path: '/likes',
        name: 'likes',
        component: LikesPage
    },
    {
        path: '/wallet',
        name: 'wallet',
        component: WalletPage
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingPage
    },
    {
        path: '/vip',
        name: 'vip',
        component: VipInfoPage
    },
    {
        path: '/my-media',
        name: 'my-media',
        component: MyMediaPage
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
