<template>
  <div class="bg-slate-950 text-white mt-4 p-5 rounded-xl">
    <div class="font-semibold">{{ $t('gifts') }}</div>
    <template v-if="items.length">
      <div class="grid grid-cols-3 gap-4 mt-5">
        <div v-for="(item, i) in items" :key="i" @click="onShowGift(item)">
          <div class="bg-slate-900 rounded-xl">
            <img :src="`/img/gifts/gift-${item.gift.id}.png`" class="w-full" />
          </div>
          <div class="text-sm mt-1 text-slate-400 whitespace-nowrap overflow-hidden overflow-ellipsis">
            {{ $t('from') }} {{ item.sender.name }}
          </div>
          <a href="#" class="mt-1 block text-sm text-slate-500 bg-slate-900 rounded-xl text-center">
            {{ $t('read_more') }}
          </a>
        </div>
      </div>
      <button v-if="hasMore" @click="loadUserGifts(currentPage + 1)" class="block w-full mt-5 px-4 py-2 bg-slate-900 text-white rounded-xl hover:bg-slate-600">
        {{ $t('load_more') }}
      </button>
    </template>
    <template v-else>
      <div class="text-gray-300 text-sm mt-2">{{ $t('not_gifts') }}</div>
    </template>
  </div>

  <TransitionRoot as="template" :show="openGift">
    <Dialog as="div" class="relative z-10" @close="onHideGift">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-slate-700 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex w-full min-h-full justify-center p-4 text-center items-start sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-xl bg-slate-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
              <div class="relative">
                <div class="flex items-start space-x-4">
                  <div>
                    <div class="bg-slate-900 rounded-xl w-20">
                      <img v-lazy="`/img/gifts/gift-${preview.gift.id}.png`" class="w-full" />
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="text-white mt-1">
                      {{ $t('from') }} <a :href="`/user/${preview.sender.id}`" class="text-pink-600 focus:outline-none">
                      {{ preview.sender.name }}
                    </a>
                    </div>
                    <div class="text-slate-400 text-sm">{{ preview.created_at }}</div>
                    <div v-if="preview.message" class="col-span-full">
                      <div class="bg-pink-500 rounded-xl p-2 mt-2 text-white">
                        {{ preview.message }}
                      </div>
                    </div>
                  </div>
                </div>
                <a @click="onHideGift" href="#" class="block w-full mt-5 px-4 py-2 bg-slate-900 text-white text-center focus:outline-none rounded-xl hover:bg-slate-600">
                  {{ $t('hide') }}
                </a>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
// import {CheckCircleIcon, ChevronLeftIcon, ExclamationCircleIcon} from "@heroicons/vue/24/outline";

export default {
  props: [
      'user_id'
  ],

  components: {
    // ChevronLeftIcon, ExclamationCircleIcon, CheckCircleIcon,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },

  methods: {
    onShowGift(item) {
      this.openGift = true
      this.preview = item
    },

    onHideGift() {
      this.openGift = false
    },

    loadUserGifts(page = 1) {
      const id = this.user_id

      this.$api.get(`user/${id}/gifts`, { params: { page } }).then((result) => {
        if (page === 1) {
          this.items = result.data.data;
        } else {
          this.items = [...this.items, ...result.data.data];
        }

        this.currentPage = page;

        if (parseInt(this.currentPage) >= parseInt(result.data.meta.last_page)) {
          this.hasMore = false
        }
      })
    }
  },

  data() {
    return {
      items: [],
      currentPage: 1,
      hasMore: true,
      openGift: false,
      preview: null,
    }
  },

  mounted() {
    this.loadUserGifts()
  }
}
</script>