<template>
  <Carousel class="rounded-xl overflow-hidden relative">
    <Slide v-for="(image, index) in images" :key="index">
      <div class="carousel__item">
        <img class="object-cover w-full" v-lazy="`https://bg.likeme.fun/${image.url}`" />
      </div>
    </Slide>
    <template #addons>
      <Navigation />
      <Pagination />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'UserImages',

  props: [
      'images'
  ],

  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },

  mounted() {
    console.log(this.images)
  }
})
</script>

<style>
.carousel__next {
  color: #fff;
}
.carousel__prev {
  color: #fff;
}

.carousel__pagination {
  position: absolute;
  z-index: 2;
  top: 5px;
  left: 0;
  right: 0;
}

.carousel__prev:hover, .carousel__next:hover {
  color: #ffffffb8;
}

.carousel__pagination-button::after {
  display: block;
  content: '';
  width: var(--vc-pgn-width);
  height: var(--vc-pgn-height);
  border-radius: 4px;
  background-color: #ffffff91;
}
.carousel__pagination-button:hover::after, .carousel__pagination-button--active::after {
  background-color: #fff;
}
.carousel__pagination-item {

}
</style>
