<template>
  <div class="min-h-full">
    <template v-if="loading">
      <div class="px-4 py-4">
        <div id="loader">
          <div class="heart">
            <span class='heartL'></span>
            <span class='heartR'></span>
            <span class='square'></span>
          </div>
          <div class="shadow"></div>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="currentProfile">
        <div class="px-4 py-4">
          <div class="rounded-xl overflow-hidden relative">
            <template v-if="currentProfile.images.length">
              <UserImages :images="currentProfile.images" />
            </template>
            <template v-else>
              <div>
                <img src="/img/photo-0.png">
              </div>
            </template>
            <div class="absolute z-10 bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent">
              <div class="px-5 py-4 text-white text-2xl font-bold">
                <div class="flex align-center">
                  <template v-if="currentProfile.is_vip">
                    <img src="/img/icon-vip1.png" class="w-8 h-8 mr-2 -ml-2" />
                  </template>
                  <div>
                    {{ currentProfile.name }}<template v-if="currentProfile.age">, {{ currentProfile.age }}</template>
                  </div>
                </div>

                <StatusOnline :user="currentProfile" />
              </div>
            </div>
          </div>
        </div>
        <div class="px-5">
          <div class="bg-black bg-opacity-50 text-white rounded-xl p-4">
            <div>
              <UserLocation :user="currentProfile" />
            </div>
            {{ currentProfile.status_text ? currentProfile.status_text : '...' }}
          </div>
        </div>
        <div class="mt-8 flex items-center justify-center space-x-4">
          <a
              @click="onDislike"
              href="#"
              class="flex items-center justify-center h-16 w-16 text-center text-white bg-black bg-opacity-20 rounded-full"
              :class="[reactionProcessing ? 'opacity-65 pointer-events-none' : '']"
          ><IconX class="w-10 h-10 opacity-80" /></a>
          <span class="text-white opacity-50">{{ $t('or') }}</span>
          <div @click="onLike" :class="[reactionProcessing ? 'pointer-events-none' : '']">
            <div class="like" aria-role="button" tabindex="0">
              <div class="like-heart-tiny like-heart-solid"></div>
              <div class="like-heart-tiny like-heart-solid"></div>
              <div class="like-heart-tiny like-heart-solid"></div>

              <div class="like-heart like-heart-white"></div>
              <div class="like-heart like-heart-solid"></div>
              <div class="like-heart like-heart-outline"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="relative flex items-center flex-col mt-10">
          <div class="px-5">
            <div class="text-white text-center text-xl mb-3">
              {{ $t('no_result_message') }}
            </div>
          </div>
          <div class="text-center">
            <img src="/img/outline-zoom-search.gif" class="w-32" />
          </div>
        </div>
      </template>
    </template>
  </div>

  <TransitionRoot as="template" :show="error.is">
    <Dialog as="div" class="relative z-10" @close="error.is = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-slate-700 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex w-full min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-xl bg-slate-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
              <div class="relative">
                <div class="text-white">{{ error.message }}</div>
                <div class="mt-5">
                  <button
                      @click="onActivateStatus"
                      :disabled="vip.processing"
                      type="button"
                      class="flex w-full justify-center rounded-md bg-pink-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
                  >
                    <svg v-if="vip.processing" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Включить VIP за 100 LFC/мес
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import IconX from "@/components/icons/IconX.vue"
import UserImages from "@/components/user/UserImages.vue"
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import StatusOnline from "@/components/user/StatusOnline.vue";
import UserLocation from "@/components/user/UserLocation.vue";

export default {
  components: {
    StatusOnline,
    UserLocation,
    IconX,
    UserImages,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot
  },

  methods: {
    onLike() {
      this.reactionProcessing = true
      this.lastReaction = 'like'

      this.$api.post('reaction', {
        user_id: this.$user.id,
        profile_id: this.currentProfile.id,
        reaction: 'like',
      }).then((result) => {
        if (result.data.status === 'ok') {
          setTimeout(() => {
            this.nextCurrentProfile(1500)
          }, 400)
        }

        if (result.data.status === 'error') {
          this.error.is = true
          this.error.message = result.data.data
        }
      })
    },

    onDislike() {
      this.reactionProcessing = true
      this.lastReaction = 'dislike'

      this.$api.post('reaction', {
        user_id: this.$user.id,
        profile_id: this.currentProfile.id,
        reaction: 'dislike',
      }).then((result) => {
        if (result.data.status === 'ok') {
          setTimeout(() => {
            this.nextCurrentProfile(1500)
          }, 1)
        }

        if (result.data.status === 'error') {
          this.error.is = true
          this.error.message = result.data.data
        }
      })
    },

    onActivateStatus() {
      this.vip.processing = true

      const id = this.$user.id

      this.$api.post(`user/${id}/vip`).then((result) => {
        this.vip.apiStatus = result.data.status
        this.vip.apiMessage = result.data.data

        if (this.vip.apiStatus === 'ok') {
          this.vip.openPopup = false
          this.vip.processing = false
          this.error.is = false
          this.error.message = ''

          if (this.lastReaction === 'like') {
            this.onLike()
          }

          if (this.lastReaction === 'dislike') {
            this.onDislike()
          }
        }

        if (this.vip.apiStatus === 'error') {
          this.$router.push({ name: '/wallet' })
        }
      })
    },

    nextCurrentProfile(timeout) {
      console.log('this.profiles', this.profiles)

      this.loading = true

      setTimeout(() => {
        if (this.profiles.length > 0) {
          this.profiles.shift()

          if (this.profiles.length > 0) {
            this.currentProfile = this.profiles[0]
          } else {
            this.loadProfiles()
          }
        }

        this.loading = false
        this.reactionProcessing = false
      }, timeout)
    },

    async loadProfiles() {
      try {
        this.loading = true;
        const id = this.$user.id;
        const response = await this.$api.get(`search?user_id=${id}`);
        console.log(response.data);

        this.status = response.data.status;
        this.profiles = response.data.data;

        if (this.profiles.length) {
          this.currentProfile = this.profiles[0];
        } else {
          this.currentProfile = null
        }
      } catch (error) {
        console.error("Failed to load profiles:", error);
      } finally {
        this.loading = false;
      }
    }
  },

  data() {
    return {
      loading: true,
      reactionProcessing: false,

      profiles: [],
      currentProfile: null,
      status: 'ok',

      lastReaction: null,

      error: {
        is: false,
        message: ''
      },

      vip: {
        processing: false,
        openPopup: false,
        apiStatus: '',
        apiMessage: '',
      }
    }
  },

  mounted() {
    if (this.$user.age === null || this.$user.gender === null || this.$user.s_gender === null) {
      this.$router.push({ name: 'settings' })
    } else {
      this.loadProfiles()
    }
  }
}
</script>