<template>
  <div class="inline-flex items-center text-pink-500 text-sm py-0.5 rounded-lg">
    <MapPinIcon class="h-5 w-5" /> <span class="ml-2">{{ user.full_address }}</span>
    <template v-if="distance">
      <span class="bg-pink-600 text-white ml-2 rounded-lg px-1">{{ formatDistance(distance) }}</span>
    </template>
  </div>
</template>

<script>
import { MapPinIcon } from '@heroicons/vue/24/outline'

export default {
  props: [
    'user'
  ],

  components: {
    MapPinIcon
  },

  methods: {
    getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
      let R = 6371; // Радиус земного шара в км
      let dLat = this.deg2rad(lat2-lat1);  // deg2rad ниже
      let dLon = this.deg2rad(lon2-lon1);
      let a =
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
          Math.sin(dLon/2) * Math.sin(dLon/2)
      ;
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      let d = R * c; // Дистанция в км
      return d;
    },

    deg2rad(deg) {
      return deg * (Math.PI/180)
    },

    formatDistance(distanceKm) {
      // Если расстояние меньше 1 км, преобразовать в метры и добавить метку "м"
      if (distanceKm < 1) {
        return `${Math.round(distanceKm * 1000)} m`;
      } else {
        // Иначе, отобразить как километры с одним десятичным знаком и добавить метку "км"
        return `${distanceKm.toFixed(1)} km`;
      }
    }
  },

  data() {
    return {
      distance: 0
    }
  },

  mounted() {
    this.distance = this.getDistanceFromLatLonInKm(this.$user.latitude, this.$user.longitude, this.user.latitude, this.user.longitude)
  }
}
</script>