<template>
  <div>
    <div class="mt-10 px-5 pb-10">
      <div class="text-white mb-5 text-xl">{{ $t('update_profile') }}</div>
      <form @submit.prevent="updateProfile" class="space-y-6" action="#" method="POST">
        <div>
          <label for="first_name" class="block text-sm font-medium leading-6 text-white">
            {{ $t('first_name') }}
          </label>
          <div class="mt-2">
            <input
                v-model="form.first_name"
                id="first_name"
                type="text"
                required
                class="block w-full rounded-md border-0 bg-white/5 pl-3 py-2.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-pink-500 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label for="last_name" class="block text-sm font-medium leading-6 text-white">
            {{ $t('last_name') }}
          </label>
          <div class="mt-2">
            <input
                v-model="form.last_name"
                id="last_name"
                type="text"
                required
                class="block w-full rounded-md border-0 bg-white/5 pl-3 py-2.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-pink-500 sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <label for="language_code" class="block text-sm font-medium leading-6 text-white">
            {{ $t('language') }}
          </label>
          <select
              v-model="form.language_code"
              id="language_code"
              required
              class="mt-2 block w-full rounded-md border-0 bg-white/5 py-2.5 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-pink-600 sm:text-sm sm:leading-6"
          >
            <option value="uk">{{ $t('lang.uk') }}</option>
            <option value="ru">{{ $t('lang.ru') }}</option>
            <option value="en">{{ $t('lang.en') }}</option>
          </select>
        </div>

        <div>
          <label for="age" class="block text-sm font-medium leading-6 text-white">
            {{ $t('age') }}
          </label>
          <select
              v-model="form.age"
              id="age"
              required
              class="mt-2 block w-full rounded-md border-0 bg-white/5 py-2.5 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-pink-600 sm:text-sm sm:leading-6"
          >
            <option value="">{{ $t('is_not_selected') }}</option>
            <option v-for="age in ages" :key="age" :value="age">{{ age }}</option>
          </select>
        </div>

        <div>
          <label for="gender" class="block text-sm font-medium leading-6 text-white">
            {{ $t('gender') }}
          </label>
          <select
              v-model="form.gender"
              required
              id="gender"
              class="mt-2 block w-full rounded-md border-0 bg-white/5 py-2.5 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-pink-600 sm:text-sm sm:leading-6"
          >
            <option value="">{{ $t('is_not_selected') }}</option>
            <option value="male">{{ $t('male') }}</option>
            <option value="female">{{ $t('female') }}</option>
          </select>
        </div>

        <div>
          <label for="s_gender" class="block text-sm font-medium leading-6 text-white">
            {{ $t('s_gender') }}
          </label>
          <select
              v-model="form.s_gender"
              required
              id="s_gender"
              class="mt-2 block w-full rounded-md border-0 bg-white/5 py-2.5 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-pink-600 sm:text-sm sm:leading-6"
          >
            <option value="">{{ $t('is_not_selected') }}</option>
            <option value="0">{{ $t('guy') }}</option>
            <option value="1">{{ $t('girl') }}</option>
          </select>
        </div>

        <div>
          <label for="s_age_from" class="block text-sm font-medium leading-6 text-white">
            {{ $t('s_age') }}
          </label>
          <div class="flex">
            <div class="flex-grow pr-2">
              <select
                  v-model="form.s_age_from"
                  id="s_age_from"
                  class="mt-2 block w-full rounded-md border-0 bg-white/5 py-2.5 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-pink-600 sm:text-sm sm:leading-6"
              >
                <option>{{ $t('from') }}</option>
                <option v-for="age in ages" :key="age" :value="age">{{ age }}</option>
              </select>
            </div>
            <div class="flex-grow pl-2">
              <select
                  v-model="form.s_age_to"
                  id="s_age_to"
                  class="mt-2 block w-full rounded-md border-0 bg-white/5 py-2.5 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-pink-600 sm:text-sm sm:leading-6"
              >
                <option>{{ $t('to') }}</option>
                <option v-for="age in ages" :key="age" :value="age">{{ age }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="col-span-full">
          <label for="status_text" class="block text-sm font-medium leading-6 text-white">
            {{ $t('status_text') }}
          </label>
          <div class="mt-2">
            <textarea
                v-model="form.status_text"
                id="status_text"
                rows="3"
                class="block w-full rounded-md border-0 bg-white/5 pl-3 py-2.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-pink-500 sm:text-sm sm:leading-6"
            />
          </div>
          <p class="mt-1 text-sm leading-6 text-gray-600">{{ $t('status_text_desc') }}</p>
        </div>

        <div>
          <button
              :disabled="formProcessing"
              type="submit"
              class="flex w-full justify-center rounded-md bg-pink-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
          >
            <svg v-if="formProcessing" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            {{ $t('update') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    loadCountries() {
      const langCode = this.$user.language_code

      this.$api.get(`countries?lang=${langCode}`).then((result) => {
        console.log('countries', result)
        this.countries = result.data

        this.loadRegions()
      })
    },

    loadRegions() {
      const langCode = this.$user.language_code
      const countryCode = this.form.country

      this.$api.get(`regions?lang=${langCode}&country=${countryCode}`).then((result) => {
        console.log('regions', result)
        this.regions = result.data
      })
    },

    updateProfile() {
      const id = this.$user.id

      this.formProcessing = true

      this.$api.post(`user/${id}`, this.form).then((result) => {
        if (result.data.status === 'ok') {
          window.location.href = '/'
        }
      }).catch((error) => {
        console.error("Ошибка обновления профиля:", error)
      }).finally(() => {
        this.formProcessing = false
      })
    }
  },

  data() {
    return {
      form: {
        first_name: this.$user.first_name,
        last_name: this.$user.last_name,
        country: this.$user.country || '',
        region: this.$user.region || '',
        language_code: this.$user.language_code || '',
        gender: this.$user.gender || '',
        age: this.$user.age || '',
        s_gender: this.$user.s_gender || '',
        s_age_from: this.$user.s_age_from,
        s_age_to: this.$user.s_age_to,
        status_text: this.$user.status_text,
      },

      formProcessing: false,

      ages: Array.from({ length: 73 }, (_, i) => i + 18),

      countries: [],
      regions: [],
    }
  },

  mounted() {
    this.loadCountries()
  }
}
</script>