<template>
  <div>
    <div class="mt-8 px-5">
      <div class="text-white text-center text-xl font-bolder mb-1">{{ $t('your_balance') }}</div>
      <div class="text-white text-center text-4xl font-bold">{{ balance }} LFC</div>
    </div>
    <div class="mt-8 px-5">
      <div class="container">
        <div class="grid grid-cols-2 gap-3">
          <div>
            <a @click="onRefillModal" href="#" class="flex items-center flex-col text-white hover:text-pink-500 rounded-xl bg-slate-800 px-2 py-3">
              <PlusCircleIcon class="w-6 h-6" />
              <div>{{ $t('up_balance') }}</div>
            </a>
          </div>
          <div>
            <a :href="shareLink" class="flex items-center flex-col text-white hover:text-pink-500 rounded-xl bg-slate-800 px-2 py-3">
              <ArrowUpOnSquareIcon class="w-6 h-6" />
              <div>{{ $t('invite_friend') }}</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-8 px-5">
      <div class="text-white text-xl font-bolder pb-3">{{ $t('story') }}</div>

      <template v-if="loading">
        <div class="text-center">
          <div role="status">
            <svg aria-hidden="true" class="fill-pink-600 inline w-8 h-8 mr-2 text-slate-500 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span class="sr-only">{{ $t('loading') }}...</span>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="transaction in transactions" :key="transaction.id" class="flex justify-between rounded-xl bg-slate-800 px-3 py-2 mb-3">
          <div>
            <div class="text-white" v-html="transaction.description"></div>
            <div class="text-slate-500 text-sm">{{ transaction.created_at }}</div>
          </div>
          <div class="text-right w-24">
            <div>
              <template v-if="transaction.status === 'success'">
                <template v-if="parseInt(transaction.type) === 2">
                  <div class="text-slate-400">-{{ transaction.amount_lfc }} LFC</div>
                </template>
                <template v-else>
                  <div class="text-green-600">+{{ transaction.amount_lfc }} LFC</div>
                </template>
              </template>
              <template v-else>
                <div :class="[transaction.status === 'pending' ? 'text-yellow-500' : 'text-red-500']">{{ transaction.amount_lfc }} LFC</div>
              </template>
            </div>
            <template v-if="transaction.status === 'success'">
              <template v-if="parseInt(transaction.type) === 2">
                <div class="text-slate-400 text-sm">{{ $t('sent') }}</div>
              </template>
              <template v-else>
                <div class="text-green-600 text-sm">{{ $t('received') }}</div>
              </template>
            </template>
            <template v-if="transaction.status === 'pending'">
              <div class="text-yellow-500 text-sm">{{ $t('pending') }}</div>
            </template>
            <template v-if="transaction.status === 'error'">
              <div class="text-red-500 text-sm">{{ $t('failed') }}</div>
            </template>
          </div>
        </div>

        <div class="mt-5 pb-5 text-slate-500 text-sm text-center">
          {{ $t('the_last_transactions_displayed') }}
        </div>
      </template>
    </div>
  </div>

  <TransitionRoot as="template" :show="openRefill">
    <Dialog as="div" class="relative z-10" @close="openRefill = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-slate-700 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-slate-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full max-w-sm sm:p-6">
              <div class="w-full">
                <div>
                  <div class="text-white text-2xl font-bolder mb-5">{{ $t('tap_up_balance') }}</div>
                  <form @submit.prevent="createOrder">
                    <div class="flex">
                      <div class="flex-auto pr-2">
                        <label for="amount" class="block text-sm font-medium leading-6 text-white">
                          {{ $t('enter_quantity_lfc') }}
                        </label>
                        <div class="mt-2">
                          <input
                              @keyup="onChangeAmount"
                              v-model="form.amount_lfc"
                              id="amount"
                              type="number"
                              required
                              min="10"
                              class="block w-full rounded-md border-0 bg-white/5 pl-3 py-2.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-pink-500 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>
                      <div class="flex-auto pl-2">
                        <label for="currency" class="block text-sm font-medium leading-6 text-white">
                          {{ $t('currency') }}
                        </label>
                        <select
                            id="currency"
                            required
                            class="mt-2 block w-full rounded-md border-0 bg-white/5 py-2.5 pl-3 pr-10 text-white ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-pink-600 sm:text-sm sm:leading-6"
                        >
                          <option value="USDT">USDT</option>
                        </select>
                      </div>
                    </div>

                    <div class="mt-5">
                      <button
                          type="submit"
                          class="flex w-full justify-center rounded-md bg-pink-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
                      >
                        <svg v-if="formProcessing" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        {{ $t('pay') }} {{ form.amount }} USDT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { PlusCircleIcon, ArrowUpOnSquareIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  components: {
    PlusCircleIcon,
    ArrowUpOnSquareIcon,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
  },

  computed: {
    shareLink() {
      const id = this.$user.id
      const text = this.$t('ref_link_text')

      return `https://t.me/share/url?url=https://t.me/likeme_fun_bot?start=${id}&text=${text}`
    },
  },

  methods: {
    onRefillModal() {
      this.openRefill = true
    },

    onChangeAmount() {
      this.form.amount = parseFloat(this.form.amount_lfc) / 10;
    },

    loadTransactions() {
      if (this.preloading) {
        return
      }

      this.preloading = true

      this.$api.get(`transactions?user_id=${this.$user.id}`)
          .then((result) => {
            this.transactions = result.data.data

            if (this.transactions.length) {
              this.balance = this.transactions[0].last_balance
            }

            this.loading = false
          })
          .catch(error => {
            console.error('Error load transactions:', error)
            this.loading = false
          })
          .finally(() => {
            this.preloading = false
          })
    },

    createOrder() {
      this.formProcessing = true
      this.form.amount = 0.01

      this.$api.post('order', this.form).then((result) => {
        this.openRefill = false

        window.location.href = result.data.pay_link
      })
    },
  },

  data() {
    return {
      openRefill: false,
      formProcessing: false,

      balance: 0.00,

      form: {
        amount_lfc: 100.00,
        amount: 100.00/10,
        currency: 'USDT',
        user_id: this.$user.id,
      },

      transactions: [],
      loading: true,
      preloading: false,

      intervalId: null,
    }
  },

  mounted() {
    this.balance = this.$user.balance

    this.loadTransactions()
    this.intervalId = setInterval(this.loadTransactions, 10000)
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
}
</script>