<template>
  <div>
    <div class="px-4 py-4">
      <template v-if="loading">
        <div class="text-center">
          <img src="/img/loading1.gif" class="rounded-xl opacity-40" />
        </div>
      </template>
      <template v-else>
        <div class="rounded-xl overflow-hidden relative">
          <template v-if="user.images.length">
            <UserImages :images="user.images" />
          </template>
          <template v-else>
            <div>
              <img src="/img/photo-0.png">
            </div>
          </template>
          <div class="absolute z-10 bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent">
            <div class="px-5 py-4 text-white text-2xl font-bold">
              <div class="flex align-center">
                <template v-if="user.is_vip">
                  <img src="/img/icon-vip1.png" class="w-8 h-8 mr-2 -ml-2" />
                </template>
                <div>
                  {{ user.name }}<template v-if="user.age">, {{ user.age }}</template>
                </div>
              </div>

              <StatusOnline :user="user" />
            </div>
          </div>
        </div>

        <div class="mt-4 flex align-center">
          <a @click="onShowGifts" href="#" class="ui-gift flex-grow mr-2 flex items-center gap-x-2 rounded-xl bg-white px-3.5 py-1.5 text-slate-950 shadow-sm hover:bg-slate-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <img src="/img/gift.png" class="w-6 h-6">
            {{ $t('send_gift') }}
          </a>
          <a :href="linkToMessage" class="flex flex-grow ml-2 items-center gap-x-2 rounded-xl bg-white px-3.5 py-1.5 text-slate-950 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <img src="/img/icon-tg-app.gif" class="w-8 h-8">
            {{ $t('send_message') }}
          </a>
        </div>

        <div class="bg-slate-950 text-white mt-4 rounded-xl">
          <div class="grid gap-px grid-cols-3">
            <div v-for="stat in stats" :key="stat.name" class="px-4 py-4">
              <p class="text-sm font-medium leading-4 text-gray-400">{{ stat.name }}</p>
              <p class="mt-2 flex items-baseline gap-x-2">
                <span class="text-2xl font-semibold tracking-tight text-white">{{ stat.value }}</span>
              </p>
            </div>
          </div>
        </div>

        <div class="bg-slate-950 text-white mt-4 p-5 rounded-xl">
          <div>
            <div>{{ $t('about') }}:</div>
            <p class="text-gray-400">
              {{ user.status_text ? user.status_text : '...' }}
            </p>
          </div>
        </div>

        <div class="bg-slate-950 text-white mt-4 p-5 rounded-xl">
          <div class="font-semibold">{{ $t('goals') }}</div>
          <div class="text-gray-300 text-sm mt-2">{{ $t('not_goals') }}</div>
        </div>

        <UserGifts :user_id="user.id" />
      </template>
    </div>
  </div>

  <template v-if="loading === false">
    <TransitionRoot as="template" :show="openGift">
      <Dialog as="div" class="relative z-10" @close="openGift = false">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-slate-700 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex w-full min-h-full justify-center p-4 text-center items-start sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-xl bg-slate-950 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:max-w-sm sm:p-6">
                <div class="relative" :class="[loadingGifts || giftForm.gift_id > 0 ? '' : 'pb-14']">
                  <template v-if="loadingGifts">
                    <div class="text-center py-10">
                      <div role="status">
                        <svg aria-hidden="true" class="fill-pink-600 inline w-8 h-8 mr-2 text-slate-500 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">{{ $t('loading') }}...</span>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="giftForm.gift_id">
                      <template v-if="giftFormProcessingSuccess">
                        <div class="flex flex-col items-center text-center">
                          <CheckCircleIcon class="w-10 h-10 text-pink-500" />
                          <div class="text-white">{{ giftFormProcessingMessage }}</div>
                        </div>
                      </template>
                      <template v-if="giftFormProcessingError">
                        <div class="flex flex-col items-center text-center">
                          <ExclamationCircleIcon class="w-10 h-10 text-red-500" />
                          <div class="text-red-500">{{ giftFormProcessingMessage }}</div>
                        </div>
                        <div class="mt-5">
                          <router-link
                              to="/wallet"
                              class="flex w-full justify-center rounded-md bg-pink-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
                          >
                            {{ $t('up_balance') }}
                          </router-link>
                        </div>
                      </template>
                      <template v-if="!giftFormProcessingSuccess && !giftFormProcessingError">
                        <div class="mb-5">
                          <a @click="onCancelGift" href="#" class="text-pink-500 flex items-center">
                            <ChevronLeftIcon class="w-5 h-5" />
                            {{ $t('back') }}
                          </a>
                        </div>
                        <div class="flex items-start space-x-4">
                          <div>
                            <div class="bg-slate-900 rounded-xl w-20">
                              <img v-lazy="`/img/gifts/gift-${giftForm.gift_id}.png`" class="w-full" />
                            </div>
                            <div class="text-white text-sm text-center mt-1">
                              {{ giftForm.price }} LFC
                            </div>
                          </div>
                          <div class="w-full">
                            <div class="col-span-full">
                              <div>
                              <textarea
                                  v-model="giftForm.message"
                                  id="status_text"
                                  rows="3"
                                  :placeholder="`${$t('enter_text_gift')}...`"
                                  class="block w-full rounded-md border-0 bg-white/5 pl-3 py-2.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-pink-500 sm:text-sm sm:leading-6"
                              />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-5">
                          <button
                              @click="sendUserGift"
                              type="button"
                              class="flex w-full justify-center rounded-md bg-pink-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
                          >
                            <svg v-if="giftFormProcessing" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            {{ $t('send_gift') }}
                          </button>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="container">
                        <div class="grid grid-cols-3 gap-2">
                          <div @click="onSelectGift(gift)" v-for="gift in gifts" :key="gift.id" class="p-1" :class="[myBalance < gift.price ? 'opacity-50 pointer-events-none' : '']">
                            <div class="bg-slate-900 rounded-xl">
                              <img v-lazy="`/img/gifts/gift-${gift.id}.png`" class="w-full" />
                            </div>
                            <div class="mt-1 text-center" :class="[myBalance < gift.price ? 'text-red-500' : 'text-white']">
                              {{ gift.price }} LFC
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="absolute left-0 bottom-0 w-full bg-slate-800 text-white rounded-xl">
                        <div class="flex justify-between items-center p-1 px-4">
                          <div>{{ $t('on_your_account') }} {{ $user.balance }} LFC</div>
                          <router-link to="/wallet" class="text-pink-600">
                            {{ $t('up_balance') }}
                          </router-link>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </template>
</template>

<script>
import UserImages from "@/components/user/UserImages.vue"
import UserGifts from "@/components/user/UserGifts.vue"
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ChevronLeftIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import StatusOnline from "@/components/user/StatusOnline.vue";

export default {
  components: {
    StatusOnline,
    UserImages,
    UserGifts,
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    ChevronLeftIcon,
    CheckCircleIcon,
    ExclamationCircleIcon
  },

  computed: {
    myBalance() {
      return parseFloat(this.$user.balance)
    },

    linkToMessage() {
      return this.user.username ? `https://t.me/${this.user.username}` : `tg://user?id=${this.user.telegram_chat_id}`
    }
  },

  methods: {
    onShowGifts() {
      this.openGift = true

      this.loadGifts()
    },

    onSelectGift(gift) {
      this.giftForm.sender_id = this.$user.id
      this.giftForm.recipient_id = this.user.id
      this.giftForm.gift_id = gift.id
      this.giftForm.price = gift.price
    },

    onCancelGift() {
      this.giftForm.sender_id = this.$user.id
      this.giftForm.recipient_id = this.user.id
      this.giftForm.gift_id = 0
      this.giftForm.price = 0
    },

    loadUser() {
      const id = this.$route.params.id

      this.$api.get('show-user/' + id).then((result) => {
        this.user = result.data.data
        this.loading = false
      })
    },

    loadGifts() {
      this.loadingGifts = true

      this.$api.get('gifts').then((result) => {
        this.gifts = result.data
        this.loadingGifts = false
      })
    },

    sendUserGift() {
      this.giftFormProcessing = true

      this.$api.post('send-gift', this.giftForm).then((result) => {
        if (result.data.status === 'ok') {
          this.giftFormProcessingSuccess = true
        } else {
          this.giftFormProcessingError = true
        }

        this.giftFormProcessingMessage = result.data.message
      })
    }
  },

  data() {
    return {
      openGift: false,

      loading: true,
      loadingGifts: true,

      gifts: [],

      user: null,

      giftForm: {
        sender_id: 0,
        recipient_id: 0,
        gift_id: 0,
        price: 0,
        message: '',
      },

      giftFormProcessing: false,
      giftFormProcessingSuccess: false,
      giftFormProcessingError: false,
      giftFormProcessingMessage: '',

      stats: [
        { name: this.$t('likes'), value: '0' },
        { name: this.$t('m_likes'), value: '0' },
        { name: this.$t('popular'), value: '0' },
      ]
    }
  },

  mounted() {
    this.loadUser()
  }
}
</script>