<template>
  <div>
    <div class="px-4 py-4">
      <div class="rounded-xl overflow-hidden relative">
        <UserImages :images="$user.images" />
        <div class="absolute z-10 bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent">
          <div class="px-5 py-4 text-white text-2xl font-bold">
            <div class="flex align-center">
              <template v-if="$user.is_vip">
                <img src="/img/icon-vip1.png" class="w-8 h-8 mr-2 -ml-2" />
              </template>
              <div>
                {{ $user.name }}<template v-if="$user.age">, {{ $user.age }}</template>
              </div>
            </div>

            <StatusOnline :user="$user" />
          </div>
        </div>
      </div>

      <div>
        <router-link to="/my-media">Добавить/Удалить фото</router-link>
      </div>

      <div v-if="!$user.is_vip" @click="onClickVip" class="mt-4 rounded-xl bg-blue-500 p-4 bg-gradient-to-r from-slate-950">
        <div class="flex">
          <div class="flex-shrink-0">
            <img class="h-12 w-12" src="/img/icon-vip1.png" alt="VIP">
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-white">Включите функционал VIP-профиля, чтобы использовать его преимущества</p>
            <p class="mt-3 text-sm md:ml-6 md:mt-0">
              <span href="#" class="whitespace-nowrap font-medium text-white">
                Подробнее
                <span aria-hidden="true"> &rarr;</span>
              </span>
            </p>
          </div>
        </div>
      </div>

      <div class="bg-slate-950 text-white mt-4 rounded-xl">
        <div class="grid gap-px grid-cols-3">
          <div v-for="stat in stats" :key="stat.name" class="px-4 py-4">
            <p class="text-sm font-medium leading-4 text-gray-400">{{ stat.name }}</p>
            <p class="mt-2 flex items-baseline gap-x-2">
              <span class="text-2xl font-semibold tracking-tight text-white">{{ stat.value }}</span>
            </p>
          </div>
        </div>
      </div>

      <div class="bg-slate-950 text-white mt-4 p-5 rounded-xl">
        <div>
          <div>{{ $t('about') }}:</div>
          <p class="text-gray-400">
            {{ $user.status_text ? $user.status_text : '...' }}
          </p>
        </div>
        <div class="flex mt-6">
          <router-link to="/settings" class="bg-slate-800 text-white px-4 py-2 rounded-xl hover:bg-gray-700">{{ $t('update_profile') }}</router-link>
        </div>
      </div>

      <div class="bg-slate-950 text-white mt-4 p-5 rounded-xl">
        <div class="font-semibold">{{ $t('goals') }}</div>
        <div class="text-gray-300 text-sm mt-2">{{ $t('not_goals') }}</div>
      </div>

      <div class="bg-slate-950 text-white mt-4 p-5 rounded-xl">
        <div class="font-semibold">{{ $t('gifts') }}</div>
        <div class="text-gray-300 text-sm mt-2">{{ $t('not_gifts') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import UserImages from "@/components/user/UserImages.vue"
import StatusOnline from "@/components/user/StatusOnline.vue";

export default {
  components: {
    StatusOnline,
    UserImages,
  },

  methods: {
    onClickVip() {
      this.$router.push({
        name: 'vip'
      })
    }
  },

  data() {
    return {
      openGift: false,

      stats: [
        { name: this.$t('likes'), value: '0' },
        { name: this.$t('m_likes'), value: '0' },
        { name: this.$t('popular'), value: '0' },
      ]
    }
  }
}
</script>