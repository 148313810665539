import { createApp } from 'vue';
import VueLazyLoad from 'vue3-lazy';
import axios from 'axios';
import i18n from './i18n';
import router from './router';
import App from './App.vue';

// Функция для создания экземпляра axios с базовыми настройками
function createAxiosInstance(initData) {
    return axios.create({
        baseURL: 'https://bg.likeme.fun/api/',
        params: {
            init_data: initData,
        },
    });
}

// Асинхронная функция для получения данных пользователя
async function fetchUserData(apiClient, app) {
    try {
        //const currentId = 438693212
        const currentId = app.config.globalProperties.$tg.initDataUnsafe.user.id;

        const response = await apiClient.get(`user/${currentId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user data:', error);
        throw error; // Переброс ошибки для дальнейшей обработки
    }
}

// Функция для инициализации глобальных свойств
async function initializeGlobalProperties(app) {
    const tg = window.Telegram?.WebApp ?? null;
    app.config.globalProperties.$tg = tg;

    if (tg) {
        //const initData = 'query_id=AAFc7SUaAAAAAFztJRrQBXKN&user=%7B%22id%22%3A438693212%2C%22first_name%22%3A%22Artem%22%2C%22last_name%22%3A%22Melnik%22%2C%22username%22%3A%22artmelnik%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1707376504&hash=b224e4f20d2e447f319424b257a164d52d813d219673b381c0fa3e5401c88452'
        const initData = tg.initData

        const apiClient = createAxiosInstance(initData);
        app.config.globalProperties.$api = apiClient;

        try {
            const userData = await fetchUserData(apiClient, app);
            app.config.globalProperties.$user = userData.data;
            i18n.global.locale = userData.data.language_code;
        } catch (error) {
            console.error('Error during global properties initialization:', error);
            // Здесь может быть логика обработки ошибок, например, отображение уведомления
        }
    }
}

// Асинхронная функция для загрузки данных и инициализации приложения
async function loadAndCreateApp() {
    const app = createApp(App);

    await initializeGlobalProperties(app); // Инициализация глобальных свойств перед монтированием

    app.use(i18n);
    app.use(router);
    app.use(VueLazyLoad, {
        loading: '/img/loading1.gif',
        error: '/img/error-img.jpeg',
    });

    app.mount('#app');
}

// Вызов функции для загрузки и создания приложения
loadAndCreateApp();

