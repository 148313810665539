<template>
  <div class="flex flex-col h-screen">
    <template v-if="isOpenTelegram">
      <div
          class="flex-1 overflow-auto pb-16"
          :class="[mapBg[$route.name]]"
      >
        <router-view></router-view>
      </div>
      <div class="fixed bottom-0 w-full bg-slate-950 text-white">
        <nav class="flex justify-around items-center p-3">
          <router-link
              to="/"
              class="block text-center rounded-full p-2"
              :class="[$route.name === 'home' ? 'bg-pink-500 hover:bg-pink-600' : '']"
          ><IconFire class="w-6 h-6 mx-auto" /></router-link>
          <router-link
              to="/likes"
              class="block text-center rounded-full p-2"
              :class="[$route.name === 'likes' ? 'bg-pink-500 hover:bg-pink-600' : '']"
          ><IconHeart class="w-6 h-6 mx-auto" /></router-link>
          <router-link
              to="/wallet"
              class="block text-center rounded-full p-2"
              :class="[$route.name === 'wallet' ? 'bg-pink-500 hover:bg-pink-600' : '']"
          ><WalletIcon class="w-6 h-6 mx-auto" /></router-link>
          <router-link
              to="/profile"
              class="block text-center rounded-full p-2"
              :class="[$route.name === 'profile' ? 'bg-pink-500 hover:bg-pink-600' : '']"
          ><IconProfile class="w-6 h-6 mx-auto" /></router-link>
        </nav>
      </div>
    </template>
    <template v-else>
      <div class="flex-1 overflow-auto bg-gradient-to-t from-slate-900 to-pink-600">
        <div class="p-5">
          <img src="/img/like-me-bot-qr.svg" />
        </div>
        <div class="mt-2 text-center text-white">
          {{ $t('qr_code') }}
        </div>
        <div class="fixed bottom-0 w-full pb-10">
          <div class="mt-5 text-center">
            <img src="/img/like-me-logo.svg" class="w-2/3 mx-auto" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import IconFire from "@/components/icons/IconFire.vue"
import IconProfile from "@/components/icons/IconProfile.vue"
import IconHeart from "@/components/icons/IconHeart.vue"
import { WalletIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'App',

  components: {
    IconFire,
    IconProfile,
    IconHeart,
    WalletIcon,
  },

  data() {
    return {
      isOpenTelegram: false,

      mapBg: {
        'home': 'bg-gradient-to-t from-slate-900 to-pink-600',
        'user': 'bg-gradient-to-t from-slate-900 to-pink-600',
        'profile': 'bg-gradient-to-t from-slate-900 to-pink-600',
        'wallet': 'bg-slate-900',
        'vip': 'bg-white',
        'settings': 'bg-slate-900',
        'likes': 'bg-slate-900',
        'my-media': 'bg-slate-900',
      }
    }
  },

  mounted() {
    if (this.$tg.initDataUnsafe.user) {
      this.isOpenTelegram = true
    }

    this.isOpenTelegram = true
  }
}
</script>

<style>
@import "./style.css";
</style>
