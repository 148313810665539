<template>
  <div>
    <div class="mt-10 px-5 pb-6">
      <div class="text-white mb-2 font-bold text-3xl">My Media</div>
    </div>

    <div class="px-5 pb-10">
      <div class="grid grid-cols-2 gap-4 auto-rows-min">
        <div v-for="(item, itemIdx) in media" :key="itemIdx">
          <div class="relative">
            <a @click="onRemovePhoto(item)" href="#" class="absolute top-2 right-2 bg-red-600 text-white p-1 rounded-full">
              <TrashIcon class="w-5 h-5" />
            </a>
            <img :src="item.url" class="w-full rounded-md" />
          </div>
        </div>
        <div @click="onAddPhotoClick" class="border border-2 border-pink-500 rounded-md border-dashed">
          <div class="relative h-full flex items-center justify-center">
            <div class="text-pink-600 flex items-center text-center flex-col py-5">
              <PlusIcon class="w-10 h-10" />
              <div>Добавить</div>
            </div>
          </div>
          <input type="file" ref="fileInput" @change="onFileSelected" accept="image/*" style="display: none;" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TrashIcon, PlusIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    TrashIcon,
    PlusIcon
  },

  methods: {
    loadMedia() {
      this.$api.get(`my-media?user_id=${this.$user.telegram_chat_id}`).then((result) => {
        this.media = result.data.data
      })
    },

    onAddPhotoClick() {
      this.$refs.fileInput.click(); // Trigger the hidden file input click
    },

    async onFileSelected(event) {
      const file = event.target.files[0]; // Get the selected file
      if (!file) {
        return; // Exit if no file is selected
      }

      const formData = new FormData();
      formData.append('file', file); // Prepare the file in a FormData object for sending

      try {
        const response = await this.$api.post(`upload-media`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.loadMedia()
        alert('Фотография была успешно добавлена.'); // Success message
      } catch (error) {
        console.error('Ошибка при добавлении фотографии:', error); // Log error to console
        alert('Произошла ошибка при добавлении фотографии.'); // Error message
      }
    },

    async onRemovePhoto(item) {
      const isConf = confirm('Вы точно хотите удалить эту фотографию?')

      if (isConf) {
        try {
          await this.$api.delete(`delete-media/${item.id}`); // Предполагается, что `item.id` содержит ID фотографии
          this.loadMedia(); // Перезагрузка списка медиа после удаления
          alert('Фотография была успешно удалена.');
        } catch (error) {
          console.error('Ошибка при удалении фотографии:', error);
          alert('Произошла ошибка при удалении фотографии.');
        }
      }
    },
  },

  data() {
    return {
      media: []
    }
  },

  mounted() {
    this.loadMedia()
  }
}
</script>