<template>
  <div class="mt-10 px-5 pb-10">
    <div class="mb-5 text-3xl font-bold flex align-center">
      {{ $t('vip_title') }}
    </div>

    <div class="mt-10">
      <div class="mb-8 flex align-center">
        <div class="flex-none w-24 bg-pink-50 rounded-full">
          <img src="/img/undraw_hooked_re_vl59.svg" class="w-24 h-24" />
        </div>
        <div class="pl-5 flex items-center">{{ $t('vip_desc_1') }}</div>
      </div>
      <div class="mb-8 flex align-center">
        <div class="flex-none w-24 bg-pink-50 rounded-full">
          <img src="/img/undraw_popular_re_mlfe.svg" class="w-24 h-24">
        </div>
        <div class="pl-5 flex items-center">{{ $t('vip_desc_2') }}</div>
      </div>
      <div class="mb-8 flex align-center">
        <div class="flex-none w-24 bg-pink-50 rounded-full">
          <img src="/img/undraw_online_popularity_re_nm0s.svg" class="w-24 h-24">
        </div>
        <div class="pl-5 flex items-center">{{ $t('vip_desc_3') }}</div>
      </div>
      <div class="mb-8 flex align-center">
        <div class="flex-none w-24 bg-pink-50 rounded-full">
          <img src="/img/undraw_online_dating_re_hu03.svg" class="w-24 h-24">
        </div>
        <div class="pl-5 flex items-center">{{ $t('vip_desc_4') }}</div>
      </div>
    </div>

    <div>
      <button
          @click="activateStatus"
          :disabled="processing"
          type="button"
          class="flex w-full justify-center rounded-md bg-pink-500 px-3 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-pink-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-500"
      >
        <svg v-if="processing" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        {{ $t('vip_btn') }}
      </button>
    </div>

    <div class="text-center mt-1 text-sm text-pink-500">
      {{ $t('vip_btn_note') }}
    </div>
  </div>

  <TransitionRoot as="template" :show="openPopup">
    <Dialog as="div" class="relative z-10" @close="openPopup = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-start sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all my-8 w-full max-w-sm p-6">
              <div>
                <template v-if="apiStatus === 'ok'">
                  <div class="mx-auto flex w-44 items-center justify-center">
                    <img src="/img/wired-outline-1103-confetti.gif" class="w-44" />
                  </div>
                </template>
                <template v-if="apiStatus === 'error'">
                  <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                </template>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                    <template v-if="apiStatus === 'ok'">
                      {{ $t('ogo') }}
                    </template>
                    <template v-if="apiStatus === 'error'">
                      {{ $t('error') }}
                    </template>
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">{{ apiMessage }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <template v-if="apiStatus === 'ok'">
                  <a href="/profile" class="inline-flex w-full justify-center rounded-md bg-pink-500 px-3 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600">
                    {{ $t('vip_status_btn_ok') }}
                  </a>
                </template>
                <template v-if="apiStatus === 'error'">
                  <router-link to="/wallet" class="inline-flex w-full justify-center rounded-md bg-pink-500 px-3 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pink-600">
                    {{ $t('vip_status_btn_error') }}
                  </router-link>
                </template>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon } from '@heroicons/vue/24/outline'

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationTriangleIcon,
  },

  methods: {
    activateStatus() {
      this.processing = true

      const id = this.$user.id

      this.$api.post(`user/${id}/vip`).then((result) => {
        this.apiStatus = result.data.status
        this.apiMessage = result.data.data

        this.processing = false
        this.openPopup = true
      })
    }
  },

  data() {
    return {
      processing: false,
      openPopup: false,

      apiStatus: '',
      apiMessage: '',
    }
  }
}
</script>